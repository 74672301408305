import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ZApp } from './ZApp';
import { AudioStart } from "./sound";

const start = async () => {
    AudioStart();
    ReactDOM.render(
        <React.StrictMode>
            <ZApp/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

start();
