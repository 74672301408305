import * as _ from "lodash";

export type PoemSelector = 0 | 1 | 2 | 3 | 4 | 5;

export interface MyPoem {
    coef: number;
    type: PoemSelector;
    idx: number;
    title: string;
    group: number[];
    poem: string;
}

export const poems: MyPoem[] = [
    {
        coef: 0.1,
        type: 0,
        idx: 0,
        title: 'Detstvo',
        group: [1, 2],
        poem: `Výkrik

Rozlúčka sa zdvihne.

Vždy, keď sa vráti,
sa vracia ako spomienka,
zloží sa v srdci ako pozlátka,
zasmeje sa, otvorí dvere,
zatvári sa, zmizne,
zmizne v ušiach.

Vždy, keď sa vracia,
zneviditeľnie a zmizne v ušiach.

Vždy, keď zmení svet,
v nej vyplašene zavrie sa ako zmiznutá rieka.

Vždy, keď zasvieti svet,
v nej vyplašene zavrie sa ako zmiznutá rieka.

Báseň

V tomto okamihu, v tomto momente,
v tomto momente, v tomto momente,
v tomto momente

detstvo?

Pozri: Silným mestom, kde sa dá aj stáť, je nádherná báseň.

Čo v tom čase si v tom čase povedala?

Pozri: V tom je všetko, čo som videla.

V rozhovore pre Denník N.


Tvoj osobný profil.`
    },
    {
        coef: 0.5,
        type: 0,
        idx: 1,
        title: 'Láska',
        group: [0, 2],
        poem: `A túžim po smútku
a túžim po svojom
a túžim po svojom
ako rúhavý oblak
s nádychom plným vody.

A túžim po smútku
a túžim po svojom,
ako vlásočnice, ktoré nesú v sieti.

A túžim po smútku
a túžim po svojom,
ako v nebi zápalky
s nádychom vody.`
    },
    {
        coef: 1,
        type: 0,
        idx: 2,
        title: 'Strach',
        group: [0, 1],
        poem: `Strach
z kostí, čo vyvolávajú nepoznanie.

Aké by to bolo?
Na svojich dverách vyhriate
svoje prázdne telo,
vzdušné nebo súvisiace s tým,
že v každej jednej osobe
chcel byť zdravý.

Aké by to bolo?`
    },
    {
        coef: 0.05,
        type: 3,
        idx: 3,
        title: 'Mýty',
        group: [4, 5, 6],
        poem: `Mýty o tom, že všetko je v poriadku, v poriadku, v poriadku, v poriadku, v poriadku.

Keď si pán Palomar dával niečo s vami, zamkol sa a odvetil:
Pozrite sa, aký bude počiatok všetkých svetov, keď bude všetko v poriadku.`
    },
    {
        coef: 0.3,
        type: 3,
        idx: 4,
        title: 'Pravda',
        group: [3, 5, 6],
        poem: `Pravda, že by sme sa mali postarať o mimoriadnu záležitosť:
tak, ako sme prežili tvorivý stav, ako sme získali pôvodné a nezameniteľné podoby všednosti, ktorá nás všeobecne priviedla k slovu a tým nás už čakala, a ktorá v tom čase získala pôvodnú povahu vecí.

Už tu nikdy nebudem stáť. Nechápem, čo by som si z toho všetkého chcela vypočuť. Či by som v tom čase vedela napísať, ako sa mám stať, čo je vlastne ja, alebo čo je vlastne ono, v ktorom sa nám to podarilo. Ak by som si to chcela vypočuť, tak by som si všimla, že si vypočujem náš časový a výrazový sviatok.

V tom čase som mala výrazový sviatok, ktorý ma nútil upútať sa k nesprávnym dôvodom, a tak som si to uvedomila. Dôvody sa mi zdali zvláštne, keď som si všimla, že v mojom živote som sa nesprávala.

Moja vina a moja vina, moja vina a moja vina.
Moja vina a moja vina, moja vina a moja vina.`
    },
    {
        coef: 2,
        type: 3,
        idx: 5,
        title: 'Holocén',
        group: [3, 4, 6],
        poem: `Počas celej doby (ročná obdoba päťdesiatych rokov) sa tu a teraz
nezmenilo svetlo. V iných časoch je však v poriadku, že sa nachádza
v neustálych a neusporiadaných riešeniach. To, čo sa nám neskončí
a s ním súvisí, je žiaduce. A tak vidím aj inú možnú báseň.

Koniec koncov, aj vo vlastnej tvorbe.

Toto mesto, takto toto mesto chce byť, chcem.`
    },
    {
        coef: 0.2,
        type: 5,
        idx: 6,
        title: 'Expanzia',
        group: [3, 4, 5],
        poem: `Expanzia.

Zostáva štvrtý rok. Ako autor sa pýta: Čo ste si mysleli?

Takže to nevieme. Hm, čo by som robil, keby som mal päťdesiatpäť.

Začína byť vyše roka.

Odvtedy, čo bolo v tom čase šesťdesiatpäť rokov.

Súhlasím.

Zistím, že to nie je úplne jedno.

Ale zdá sa mi, že to nebolo jedno.

Mám ťa rada.

Jeho cesta je vyššia.

Raz som sa vrátil z vesmírnej krajiny.

Ako sa voláš?

Ako sa voláš?

Ako sa voláš?

Nie.

Máš rada?

Máš niekoho?`
    },
    {
        coef: 0.3,
        type: 4,
        idx: 7,
        title: 'Zem a predkovia krásy',
        group: [8, 9, 10],
        poem: `Zem
a predkovia krásy.
V požičovniach vám vravím:
Vykríknuť z piesku,
výkrik svojej veľkosti.
Keď sa premeníš na všetko,
čo sa v nás opäť zatvári,
ja v mojom srdci
sa zatváram.

Ako prázdne tango,
takmer ako vianočné balóniky.
Zem sa začína v tvojej záhrade, človek sa však začína v tvojich útrobách. Zdá sa, že s tým súhlasím. Hm, ale toto je ono! 

Zemou vystupuje pravá záhrada.

A kto je tvoje telo?

Čo ti zostane?

Mne sa vždy zdá, že sa môj zrak začína meniť.

Je to moje telo.

V mojej poézii nechcem byť úprimný.

Záhrada sa nám však zmení na skutočnú povahu, začína sa postupne meniť, meniť na skutočnosť, začína sa meniť na svoje telo.

Mne sa však zdá, že toto nie je vôbec neskutočné.

A to je prirodzené.

Som tvoj strýko a moje telo je vôňou vesmírnej energie.

Máš na sebe svoju hlavu, však?

Neviem, čo si o sebe myslím.

A čo si o mne myslíš?

Mám rada, že to nie je vôbec neskutočné.

Možno je to tvoje meno.

Moja hlava znie ako nejaký iný zelený prízvuk.`
    },
    {
        coef: 1.2,
        type: 4,
        idx: 8,
        title: 'Vzduch sa nedá naučiť',
        group: [7, 9, 10],
        poem: `Vzduch sa nedá naučiť. Vzduch je obrovský, čoraz viac sa na nás díva. Vzduch je vzdialený, čo sa dá vnímať ako obrovský, ako obrovský vzduch.

Na druhý deň sa v Moskve pozrieme hore nohami a naše pohľady sa zatvoria okolo seba. Vzduch má svoju tvár. Vyzerá ako malé dievča.

Moja tvár je, že keď má otvorené oči, je to celé tu.

Moja tvár je, že keď sa pozeráme na všetko, čo sa dá vnímať ako obrovský vzduch, je to celé tu.

Vzduch sa nedá naučiť, ale
naďalej hľadá nádej.`
    },
    {
        coef: 1,
        type: 5,
        idx: 9,
        title: 'Tatry, ktoré už dlho nesedeli',
        group: [7, 8, 10],
        poem: `Tatry, ktoré už dlho nesedeli, sa dali do pohybu. Zostali
tu aj naďalej: naše detské sestry, s ktorými si tu bola,
a naša sestra, s ktorou tu bola. Tak sa to dostalo,
keď začali vidieť, a vtedy si dali prázdnu kašu aj
dnu.

A keď bola v nejakom kostole, všetko sa zmenilo,
a celkom iste, že si tu bola ako priateľka,
a ona, ktorá sa neskôr začala rozprávať o svojich
skúsenostiach a o svojej existencii.

A keď sa všetko dozvie, že máš pravdu, odpovedala
si vtedy, ako veľmi chcela.

A vzal jej časopis.
A nevedela, či jej bol priateľom alebo priateľkou.
A celkom dobré, že jej priateľom bol tatér,
a celkom iste, že jej priateľ bol slobodným
a svojím spôsobom vykľuvaným ja.

Na druhý deň však bola v situácii, keď sa vrátila,
dostala povolenie v detstve, pretože jej matka
bola vlastne naďalej vydatá.

A tatér bol v poriadku.
A najväčší priestor mal zachovaný
v čase, keď začali chodiť do kostola.`
    },
    {
        coef: 1.6,
        type: 5,
        idx: 10,
        title: 'Pralesy, vzdychol si',
        group: [7, 8, 9],
        poem: `Pralesy, vzdychol si,
keď si mi prebral zápach z očných krídel.
Chvíľu si ho nechal, vziať ruky,
no vzlykol si, keď sa pozrel.
Svoj sen, ktorý mi vykríkol na cestu,
zdalo sa mi, že som vždy obrátený,
a nevzpieraj sa,
že som z teba vyrástol.
Myslel si, že som vyrástol,
lebo som vyrástol ako mŕtvy.

Pralesy, vzdychol si, nech sa páči,
keď škriabeš, tak len chodíš.

Dlho je do rána v tvojom náručí,
čo ti došlo na zdravie.
S jedným plameňom sa vráti,
s kvitnúcou päsťou ti vlasy,
tvoje prsty, sladké ako voda,
život ti v prstoch očistí,
život ti v prstoch vyráža,
život ti v prstoch vzdychuje,
život ti v prstoch vzdychuje,
život ti v prstoch vyhrabe`
    },
    {
        coef: 0.2,
        type: 2,
        idx: 11,
        title: 'Neurónová sieť',
        group: [12],
        poem: `Neurónová sieť.
Vysoko. Na konci svojej izby.
Poďakuje.
Chytí si do ruky.
Vytiahne si ju za ruku.
Toto je prázdno.
Vypne si jednu nohu cez nohu.
Vytiahne si ju nohou cez nohu.
Začne sa pozerať.
Poďakuje.
Toto je prázdno.
Poďakuje.
Vypne si jednu nohu cez nohu.
Začne sa pozerať.
Toto je prázdno.`
    },
    {
        coef: 2.5,
        type: 2,
        idx: 12,
        title: 'Elektronická poézia',
        group: [11],
        poem: `Elektronická poézia
dôvody nezahŕňa: vedie cez zobrazenie
nezrozumiteľnosti.
Voda
z kryštálov (hĺbkových polí)
zabudnutá
pravá ruka
(zmietaná voda)
pozemská spojitosť
pravá ruka
(zmietaná voda)
pozemská dôvera
pravá ruka
(zmietaná voda)
(zmietaná voda)
zatvárame sa`
    },
    {
        coef: 3,
        type: 2,
        idx: 13,
        title: '1',
        group: [14, 15, 16, 17],
        poem: `Femme fatale je vzdialená.

Odvážne zdvihnúť šaty, vyskočiť z vody, odtiahnuť vodu,
položiť na zem, spamätať sa.

Vidím, že stromy sú čisté a vznešené,
doliny,
v ktorých kráčaš ako vystrihnutá postava,
sú čisté a vznešené.

Daj mi čerstvý vzduch, vystieraš ruku,
zapínaš tvár a čítaš noviny,
pokožka sa zmení na krásu,
rozmýšľaš, či vzdychajú alebo nie.

Hovorím s nimi a oni si prisvojujú.

Tvoj život je iný, tvoj hlas je iný,
tvoje oči si myslia, že sme nikdy netvorili svoje telo,

že sme sa nerozprávali.`
    },
    {
        coef: 1,
        type: 1,
        idx: 14,
        title: '3',
        group: [13, 15, 16, 17],
        poem: `Tvoje slzy sa menia na
hruď, ktorou chcem zomrieť.
V záhrade tvoje slzy
zasvietia ako detské sliepky.
Časom si otvorím tvár.
Pomaly zapadáš do nekonečného vzduchu.
Znova sa pozriem na kameň.
Vzduch mlčí.
A nebo s tebou
nie je až také veselé.
Keď začnem čítať listy,
opatrne skáčem za hlavu.
A pred zrkadlom
zatvorím oči.
V tele sa vykloním a nadchnem sa.
Kde je ten chladný svet?
Nemôžem toľko poznať.
Toto by som nebola ja.`
    },
    {
        coef: 2,
        type: 1,
        idx: 15,
        title: '9',
        group: [13, 14, 16, 17],
        poem: `Budúcnosť je dnes, už teraz,
len nábytok sa čerstvo šije.
Takáto bytosť,
chodíme do vesmíru
a dostaneme nebo
v týchto rúchach.
Sám do vesmírnej,
skrývajúc sa vesmíru,
svet je vesmírna chyba.`
    },
    {
        coef: 3,
        type: 1,
        idx: 16,
        title: '19',
        group: [13, 14, 15, 17],
        poem: `Tento príbeh je príbehom
súčasnej slovenskej poézie. V tomto
čase je náš svet, ktorý je
v porovnaní s mimoriadnou osobnosťou,
a príbeh o dôležitosti výpovede
a dôležitosti vlastného údelu.
Tento príbeh je príbehom
súčasnej slovenskej poézie.`
    },
    {
        coef: 0.1,
        type: 1,
        idx: 17,
        title: '20',
        group: [13, 14, 15, 16],
        poem: `A znova sa mi zdalo, že v tomto momente si bola celkom istá, že
v nej vyrastala a že všetko, čo som sa dozvedela, je jednoduché.

Záver`
    },

    {
        coef: 0.5,
        type: 0,
        idx: 18,
        title: 'Biela noc 2021',
        group: [19, 20, 21, 22, 23, 24, 25],
        poem: `Niečo sa zmenilo,
keď sa všetko zasvietilo.
Keď všetko vyvstalo,
keď sa všetko približovalo,
keď sa všetko príjemné odtiaľto
pokrylo do noci.
Keď všetko zmizlo,
keď všetko vyvstalo,
keď si všetko spolu sľubovalo
a všetko sa vydávalo,
keď sa všetko čítalo,
keď sa všetko prehliadalo,
keď si všetko spieva v tme,
keď sa všetko zasvietilo.
Keď sa všetko vyšvihlo a všetko sa priblížilo,
keď sa všetko rozkladalo,
keď všetko začalo kričať,
keď sa všetko zasvietilo.
Keď všetko šumelo krídlami
a všetko sa zasvietilo,
keď sa všetko zapchalo,
keď všetko začalo ako čerstvé vločky,
keď všetko vyvstalo,
keď sa všetko zasvietilo.
Keď sa všetko nenarodilo
a všetko sa pochovalo,
keď všetko vyvstalo,
keď všetko začalo byť čoraz menšie.
Keď všetko začalo čítať,
keď sa všetko približovalo,
keď sa všetko vydávalo,
keď sa všetko priblížilo krídlami.`
    },
    {
        coef: 0.2,
        type: 3,
        idx: 19,
        title: 'Biela noc',
        group: [18, 20, 21, 22, 23, 24, 25],
        poem: `a všetko je zdravé.

Kráčať do nášho domu,
no nevzdávať sa,
no vzdychať si,
no odísť.

Odíde.
Ako voda,
ako potulný strom,
no vzdychať si.

Máš trinásť rokov,
no neskôr sa budeš vracať,
ale nezávidieť.

No strach,
no závrat,
no mier,
no držaný,
no zánik,
no neskôr,
s kým by si sa vzdala,
s kým by si sa vzdala,
s kým by si sa vzdala.

No strach,
no závrat,
no vzdychať si,
no odísť.

Ako voda,
no odísť,
no odísť.

Ako potulný strom,
no nevzdávať sa,
no vzdychať si,
no odísť.

Pozeraj na seba,
ktorú si vyčítaš.

Možno,
možno sa teš so sebou.
Čo si vyčítaš?
Čo si vyčítaš?

V tejto chvíli
si prezieravý vzdych
nezmestíš.

Stále čakám
na teba.

Hľadám
teba,
tvoju tvár,
tvoje telo.
Hľadám
teba,
moje telo,
moje telo,
tvoje telo,
tvoje telo.

Stále.`
    },
    {
        coef: 1,
        type: 1,
        idx: 20,
        title: 'Biela noc',
        group: [18, 19, 21, 22, 23, 24, 25],
        poem: `V noci začali
stáť pri chôdzi

Dnes sa otváram
do dedičstva
na chodník v rýme

Zablúdila som
a práve som vyskočila
v zápche

Moja hlava sa roztápa
do ticha

Všetko je to, čím som sa stala.
Noc je to, čím som sa stala.
Kráčať v tomto štádiu
s úľavou.
Kráčať v tomto štádiu.
Budúcnosť je to, čím som sa stala.`
    },
    {
        coef: 2,
        type: 3,
        idx: 21,
        title: 'Biela noc',
        group: [18, 19, 20, 22, 23, 24, 25],
        poem: `Biela noc, štít,
Rozlúčka škriabe kvet,
Otvoríš svoju hlúposť,
Vypneš námestia,
Zlomíš si vôňu,
Naškriabeš,
Pripínaš si žalostné lampy,
Vyhrabeš,
Zaplniť čas,
Ktorým pôjdeš,
Rozškriabeš,
Kričíš,
Pridáš,
Kričíš,
Škriabeš`
    },
    {
        coef: 0.2,
        type: 4,
        idx: 22,
        title: 'Literárna Bratislava',
        group: [18, 19, 20, 21, 23, 24, 25],
        poem: `Literárna Bratislava
aktívne sa začala venovať aj voľnej noisovej literatúre.

Aké sú umelé poetky?
Nielen umelé poetky, ale aj umelci, ktorí majú
plnohodnotnú súťaž a plnohodnotnú kultúru.
Na čom záleží?
Neviem, ja nie som umelý autor.
Mám však pocit, že mám už v živote veľa
spoločného s autormi.
Ako vnímate seba?
Naša súťaž vôbec neexistuje`
    },
    {
        coef: 0.75,
        type: 5,
        idx: 23,
        title: 'Bratislava',
        group: [18, 19, 20, 21, 22, 24, 25],
        poem: `Budú vychovávať.
Budú sa pozerať na nás, ktorí dávame zmysel pre humor.
Budú začínať odznova a znova, ako keby vedľa nás niekto vyšiel na chvíľu alebo zmizol.
Budú vidieť svoje cesty, ktoré si v sebe nosia, hoci nie tú svoju.
Budú vychádzať z toho, čo nás priviedlo na svoje miesto.
Budú si pre seba sadať a učiť sa vychutnávať.

Bratislava,
kde v noci zapadá slnko.
A tak to vyzeralo ako toľko slnka.
A tak sa z tejto slnečnosti vzpierame,
a tak sa toho zbavíme,
keď na všetkých slovách vytváram steny,
ktoré prehĺtame.

Kráčame v predizbe a všade je vybielené,
ale celkom nevyjdeme,
stopy vyplníme z komínov,
všetko sa zmení.

Ale odkiaľ na mňa pozriete,
kam sa zamiešame,
keď sa na mňa pozriete,
kde sa stávame.

A tak sa vzpierame,
a tak sa vzpieram,
keď sa na mňa pozriete,
kde sa stávame.`
    },
    {
        coef: 0.2,
        type: 5,
        idx: 24,
        title: 'September 2021',
        group: [18, 19, 20, 21, 22, 23, 25],
        poem: `Cudzinec, ktorý sa nám v slovenskom živote nedodá.
Náš malý človek.
Jeho oči, oči z mäsa, ktoré nikdy nenájde,
zaklínadlom je zápach jeho tela.
Nám ho nikto nenaučí,
vraví výsmechom.

Kým začnú rána vypadávať,
kým sa v ruke začnú vystierať.
A teraz sa začnú ťahať po schodoch,
v ktorých by sa nedalo vyšetriť.

Kým sa všetko vyvíja,
všetko sa začína, ale keď si to všetko predstavovala,
všetko sa zmenilo.

Kým začnú rána vypadávať,
kým sa vráti zo svojho sna.

Kniha ako celok?
Chcem povedať, že v pozadí pramení čosi zdanlivo
zrozumiteľné, ale vlastne, nie je to celkom tak.

Ako je možné, že pozornosť, ktorá je v pozadí
konkrétnych pozadí, je zároveň v pozadí toho,
kto si to, čo je v každom okamihu, v tomto okamihu
vlastnej existencie, zachováva?

V pozadí pramení zdanlivo
zrozumiteľne, ale vlastne, nie je to celkom tak.

Môže sa stať, že pozdravom
zabudnem na všetko, čo mám poznačené
od základov.`
    },
    {
        coef: 3,
        type: 2,
        idx: 25,
        title: 'Rómerov dom',
        group: [18, 19, 20, 21, 22, 23, 24],
        poem: `Rómerov dom, za ktorým
sa pozvoľna pohladíš.
Začína sa rozpadať ako vták, ktorý sa
premení na jeho vnútro.
Zvnútra sa ozýva ako zástava.
Začína sa prebíjať ako živočích,
no nezávisí od ničoho,
čo by si mohol vidieť.
Začína sa rozpadať ako vták, ktorý sa
premení na jeho vnútro.
Znie to ako zástava, ktorá sa rozpadala 
v tichom svetle.
Začína sa otvárať kolobeh dňa,
no nezávisí od ničoho.
Znie to ako zástava, ktorá sa rozpadala
v tichom svetle.
Máš pocit, že domov je súčasťou
slovenskej poézie?
Rómerov dom, v ktorom sa vracia
súčasná nádej, ktorá sa však začína 
v tomto prípade spoločnými živlami,
začína už aj v živote.

Rómerov domov, 
v ktorom sa všetci poznajú.`
    }
];

export const shuffled: MyPoem[] = _.shuffle<MyPoem>(poems);

export const angle = Math.PI * 2 / poems.length;

export const links = shuffled.reduce((ret: Array<[number, number]>, poem, i) => {
    shuffled.forEach((item, j) => {
        if (poem.group.includes(item.idx)) {
            ret.push([i, j]);
        }
    });
    return ret;
}, []);
