import * as React from 'react';

export const Live: React.FC = () => {
    return (
        <>
            <h1>Prezentácie projektu Liza Gennart</h1>
            <h2>Uvedenie projektu na Nasupišti 1-12</h2>
            <p>
                <iframe
                    className="fb"
                    src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNastupiste%2Fposts%2F5646926642044993&show_text=true&width=500"
                    />
            </p>
        </>
    );
}
