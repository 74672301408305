import * as React from 'react';

import close from '../img/close.svg'

interface Props {
    children?: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
    onModalClose: () => void;
}

export const Modal: React.FC<Props> = ({onModalClose, children}) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <aside>
                    <img src={close} className="modal-close" onClick={(e) => {
                        e.preventDefault();
                        onModalClose();
                    }}/>
                </aside>
                <section className="modal-main">
                    {children}
                </section>
            </div>
        </div>);
}
