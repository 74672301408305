import * as React from 'react';
import axios from "axios";


interface Message {
    user: boolean;
    text: string;
}

export const Chat: React.FC = () => {
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [userMessage, setMessage] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    return (
        <>
            <h1>Talk to Liza</h1>
            <form onSubmit={async (e) => {
                e.preventDefault();
                if (!loading) {
                    setLoading(true);
                    messages.push({
                        user: true,
                        text: userMessage,
                    });
                    setMessages(messages);
                    setMessage('');
                    try {
                        const data = await axios.post('/api/chat/', {messages});
                    } catch (e) {

                    }
                    setLoading(false);
                }
            }}>
                <input type="text" placeholder="Napís mi niečo pekné a ja ti odpoviem" value={userMessage}
                       onChange={(e) => {
                           setMessage(e.target.value);
                       }}/>
            </form>
            <section>
                <ul className="chat">
                    {messages.map((item) => {
                        return (
                            <li>
                                <strong>{item.user ? 'TY' : 'LIZA'}:</strong>
                                {item.text}
                            </li>
                        );
                    })}
                    {loading ? (<li>čakám na odpoveď</li>) : null}
                </ul>
            </section>
        </>
    );
}
