import * as _ from 'lodash';
import React, { useRef } from 'react';
import { MyPoem } from "./poems";
import { Text } from '@react-three/drei';
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { color, getGrid, fontProps } from "./constants";

interface WProps {
    word: string;
    x: number;
    y: number;
    r: number;
    coef: number;
}


const Word = ({word, x, y, r, coef}: WProps) => {
    const ref = useRef();
    useFrame(() => {
        if (ref) {
            (ref.current as any).material.side = THREE.DoubleSide;
            switch (r) {
                case 1:
                    (ref.current as any).rotation.z += THREE.MathUtils.randFloat(-0.02, 0.05) * coef;
                    (ref.current as any).position.y += THREE.MathUtils.randFloat(0.05, 0.2) * coef;
                    break;
                case 2:
                    (ref.current as any).rotation.y += 0.05 * coef;
                    (ref.current as any).position.z += THREE.MathUtils.randFloat(0.05, 0.2) * coef;
                    break;
                case 3:
                    (ref.current as any).rotation.x -= 0.07 * coef;
                    (ref.current as any).rotation.z += THREE.MathUtils.randFloat(0.01, 0.05) * coef;
                    break;
            }
        }
    });
    return (<Text
        ref={ref}
        {...fontProps}
        fontSize={THREE.MathUtils.randFloat(0.8, 3)}
        anchorX="center"
        children={word}
        position={[x, -1 * y, THREE.MathUtils.randFloat(-15, 15)]}
        color={color}
    />)
}

interface gProps {
    words: string[];
    letters: { x: number, y: number }[];
    coef: number;
}

const Grp = ({words, letters, coef}: gProps) => {
    const ref = useRef();
    useFrame(() => {
        (ref.current as any).rotation.x += 0.001 * coef;
        (ref.current as any).rotation.y += 0.02 * coef;
    });

    return (
        <mesh
            ref={ref}
        >
            {letters.map((item, i) => {
                const word = _.sample(words);
                return (
                    <Word coef={coef} word={word as string} key={i} x={item.x} y={item.y}
                          r={THREE.MathUtils.randInt(0, 10)}/>
                )
            })}
        </mesh>)
}

export class GlyphC extends React.Component<{ poem: MyPoem }, { current: number; }> {
    constructor(props: any) {
        super(props);
        this.state = {
            current: THREE.MathUtils.randInt(0, this.props.poem.title.split(' ').join(' ').length - 1),
        }
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<{ poem: MyPoem }>, nextContext: any) {
        this.setState({
            current: 0
        })
    }

    render() {
        const {current} = this.state;
        const {poem} = this.props;
        const letters = getGrid(poem.title[current]);
        const words = poem.poem.split("\n").map(item => item.trim()).join(" ").split(" ");

        return (
            <Grp coef={poem.coef} words={words} letters={letters}/>
        );
    }
}
