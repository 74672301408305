import * as _ from "lodash";
import * as Tone from 'tone';
import * as mm from '@magenta/music';
import * as Tonal from 'tonal';
import * as StartAudioContext from 'startaudiocontext';

const MIN_NOTE = 48;
const MAX_NOTE = 83;
const NO_EVENT = -2;
const NOTE_OFF = -1;
const STEPS_PER_CHORD = 16;
const MODES = [
    [2, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 2, 2, 1, 2],
    [1, 2, 2, 2, 1, 2, 2],
    [2, 2, 2, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 2],
    [2, 1, 2, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 2, 2]
];
const KEYS = [
    'C4',
    'G3',
    'D4',
    'A3',
    'E4',
    'B3',
    'F#4',
    'C#4',
    'G#3',
    'D#4',
    'A#3',
    'F4'
];

let key = Tone.Frequency(_.sample(KEYS)).toMidi();
let mode = _.sample(MODES);
let melodyLine: any[] = [];
let generatedChords = new Map();
let pendingActions: any[] = [];


let LAST_BPM = 10;

Tone.Transport.bpm.value = LAST_BPM;
Tone.context.latencyHint = 'playback';

function buildScale(tonic: any, mode: any) {
    return mode
        .concat(mode)
        .reduce((res: any, interval: any) => res.concat([_.last(res) + interval]), [tonic]);
}

function getPitchChord(degree: any, tonic: any, mode: any) {
    let scale = buildScale(tonic, mode);
    let root = scale[degree];
    let third = _.includes(scale, root + 4) ? root + 4 : root + 3;
    let fifth = _.includes(scale, third + 4) ? third + 4 : third + 3;
    return [root % 12, third % 12, fifth % 12];
}

function getChordRootBasedOnLast(degree: any, tonic: any, mode: any, last: any) {
    let rootMid = buildScale(tonic, mode)[degree];
    let rootLow = rootMid - 12;
    let rootHigh = rootMid + 12;
    let options = [rootMid, rootLow, rootHigh].filter(
        n => n >= MIN_NOTE && n <= MAX_NOTE
    );
    return Math.random() < 0.75
        ? _.minBy(options, r => Math.abs(r - last))
        : _.sample(options);
}

// Beethoven's chord progression probabilities

// 0 = I, 1 = ii, etc.
var chordProgressions = new Tone.CtrlMarkov({
    0: [
        {value: 1, probability: 0.1},
        {value: 2, probability: 0.01},
        {value: 3, probability: 0.13},
        {value: 4, probability: 0.52},
        {value: 5, probability: 0.02},
        {value: 6, probability: 0.22}
    ],
    1: [
        {value: 0, probability: 0.06},
        {value: 2, probability: 0.02},
        {value: 3, probability: 0.0},
        {value: 4, probability: 0.87},
        {value: 5, probability: 0.0},
        {value: 6, probability: 0.05}
    ],
    2: [
        {value: 0, probability: 0.0},
        {value: 1, probability: 0.0},
        {value: 3, probability: 0.0},
        {value: 4, probability: 0.67},
        {value: 5, probability: 0.33},
        {value: 6, probability: 0.0}
    ],
    3: [
        {value: 0, probability: 0.33},
        {value: 1, probability: 0.03},
        {value: 2, probability: 0.07},
        {value: 4, probability: 0.4},
        {value: 5, probability: 0.03},
        {value: 6, probability: 0.13}
    ],
    4: [
        {value: 0, probability: 0.56},
        {value: 1, probability: 0.22},
        {value: 2, probability: 0.01},
        {value: 3, probability: 0.04},
        {value: 5, probability: 0.07},
        {value: 6, probability: 0.11}
    ],
    5: [
        {value: 0, probability: 0.06},
        {value: 1, probability: 0.44},
        {value: 2, probability: 0.0},
        {value: 3, probability: 0.06},
        {value: 4, probability: 0.11},
        {value: 6, probability: 0.33}
    ],
    6: [
        {value: 0, probability: 0.8},
        {value: 1, probability: 0.0},
        {value: 2, probability: 0.0},
        {value: 3, probability: 0.03},
        {value: 4, probability: 0.0},
        {value: 5, probability: 0.0}
    ]
});
chordProgressions.value = 0;

let temperature = 1.3;

// Using the Improv RNN pretrained model from https://github.com/tensorflow/magenta/tree/master/magenta/models/improv_rnn
const rnn = new mm.MusicRNN(`${process.env.PUBLIC_URL}/model`);
rnn.initialize();

function detectChord(notes: any) {
    notes = notes.map((n: any) => Tonal.Note.pc(Tonal.Note.fromMidi(n))).sort();
    return Tonal.PcSet.modes(notes)
        .map((mode, i) => {
            const tonic = Tonal.Note.name(notes[i]);
            const names = (Tonal.Dictionary.chord as any).names(mode);
            return names.length ? tonic + names[0] : null;
        })
        .filter(x => x);
}

let lastGenerated: any;

function generateChord(chordDegree: any, key: any, mode: any) {
    let chords = detectChord(getPitchChord(chordDegree, key, mode));
    let chord = _.first(chords) || 'Cm';
    console.log('chord', chord);
    let last = key;
    if (lastGenerated) {
        for (let i = lastGenerated.length - 1; i > 0; i--) {
            if (lastGenerated[i] > 0) {
                last = lastGenerated[i];
                break;
            }
        }
    }
    let seedSeq = toNoteSequence([
        getChordRootBasedOnLast(chordDegree, key, mode, last)
    ]);
    return rnn
        .continueSequence(seedSeq, STEPS_PER_CHORD, temperature, [chord])
        .then(seq => {
            lastGenerated = seq.notes.map((n: any) => n.pitch);
            let result: any[] = [];
            let fromChord = {chordDegree, key, mode};
            for (let {pitch, quantizedStartStep} of seq.notes) {
                while (
                    result.length === 0 ||
                    _.last(result).indexInChord < quantizedStartStep - 1
                    ) {
                    result.push({
                        note: -2,
                        indexInChord:
                            result.length === 0 ? 0 : _.last(result).indexInChord + 1,
                        fromChord
                    });
                }
                result.push({
                    note: pitch,
                    indexInChord: quantizedStartStep,
                    fromChord
                });
            }
            return result;
        });
}

function toNoteSequence(seq: any) {
    let notes = [];
    for (let i = 0; i < seq.length; i++) {
        if (seq[i] === -1 && notes.length) {
            // @ts-ignore: Object is possibly 'undefined'
            _.last(notes).endTime = i * 0.5;
        } else if (seq[i] !== -2 && seq[i] !== -1) {
            // @ts-ignore: Object is possibly 'undefined'
            if (notes.length && !_.last(notes).endTime) {
                // @ts-ignore: Object is possibly 'undefined'
                _.last(notes).endTime = i * 0.5;
            }
            notes.push({
                pitch: seq[i],
                startTime: i * 0.5
            });
        }
    }
    // @ts-ignore: Object is possibly 'undefined'
    if (notes.length && !_.last(notes).endTime) {
        // @ts-ignore: Object is possibly 'undefined'
        _.last(notes).endTime = seq.length * 0.5;
    }
    return mm.sequences.quantizeNoteSequence(
        {
            ticksPerQuarter: 220,
            totalTime: seq.length * 0.5,
            quantizationInfo: {
                stepsPerQuarter: 1
            },
            timeSignatures: [
                {
                    time: 0,
                    numerator: 4,
                    denominator: 4
                }
            ],
            tempos: [
                {
                    time: 0,
                    qpm: 120
                }
            ],
            notes
        },
        1
    );
}

// Impulse response from Hamilton Mausoleum http://www.openairlib.net/auralizationdb/content/hamilton-mausoleum
const reverb = new Tone.Convolver(`${process.env.PUBLIC_URL}/sound/hm2_000_ortf_48k.mp3`).toMaster();

const samples = {
    C3: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-C4.mp3`
    ),
    'D#3': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-Ds2.mp3`
    ),
    'F#3': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-Fs2.mp3`
    ),
    A3: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-A2.mp3`
    ),
    C4: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-C3.mp3`
    ),
    'D#4': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-Ds3.mp3`
    ),
    'F#4': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-Fs3.mp3`
    ),
    A4: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-A3.mp3`
    ),
    C5: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-C4.mp3`
    ),
    'D#5': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-Ds4.mp3`
    ),
    'F#5': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-Fs4.mp3`
    ),
    A5: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-A4.mp3`
    ),
    C6: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-C5.mp3`
    ),
    'D#6': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-Ds5.mp3`
    ),
    'F#6': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-Fs5.mp3`
    ),
    A6: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/harp-A5.mp3`
    )
};

const bassSamples = {
    C0: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/bass-C0.mp3`
    ),
    'D#0': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/bass-Ds0.mp3`
    ),
    'F#0': new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/bass-Fs0.mp3`
    ),
    A0: new Tone.Buffer(
        `${process.env.PUBLIC_URL}/sound/bass-A0.mp3`
    )
};
const sampler = new Tone.Sampler(samples).connect(new Tone.Gain(0.05).connect(reverb));
const echoedSampler = new Tone.Sampler(samples)
    .connect(new Tone.PingPongDelay('16n', 0.8).connect(new Tone.Gain(0.05).connect(reverb)))
    .connect(new Tone.Gain(0.05).connect(reverb));

const bassSampler = new Tone.Sampler(bassSamples).connect(
    new Tone.Gain(0.05).connect(reverb)
);

const bassLowSampler = new Tone.Sampler(bassSamples).connect(
    new Tone.Gain(0.01).connect(reverb)
);

function generateNext(time: any) {
    while (pendingActions.length) {
        let action = pendingActions.shift();
        let uiDelay = melodyLine.length * Tone.Time('16n').toSeconds();
        key = action.key;
        mode = action.mode;
        chordProgressions.value = 0;
        Tone.Draw.schedule(() => {
            LAST_BPM = LAST_BPM > 90 ? _.random(10, 40) : _.random(100, 130)
            Tone.Transport.bpm.value = LAST_BPM;
            action.onDone();
        }, time + uiDelay);
    }

    let chord = chordProgressions.value;
    chordProgressions.next();
    let mapKey = `${chord}-${key}-${mode}`;
    if (generatedChords.has(mapKey) && Math.random() < 0.6) {
        melodyLine = melodyLine.concat(generatedChords.get(mapKey));
        return Promise.resolve(true);
    } else {
        return generateChord(chord, key, mode).then(melody => {
            melodyLine = melodyLine.concat(melody);
            generatedChords.set(mapKey, melody);
        });
    }
}

let releasePrev: any;
let timeStep = 0;

function playNext(time: any) {
    if (timeStep++ % STEPS_PER_CHORD === STEPS_PER_CHORD - 5) {
        generateNext(time);
    }
    if (melodyLine.length === 0) {
        return;
    }
    let {fromChord, note, indexInChord} = melodyLine.shift();
    if (note !== -2 && note !== -1) {
        if (releasePrev) {
            releasePrev(time);
            releasePrev = null;
        }
        releasePrev = playNote(note, time);
    } else if (note === -1 && releasePrev) {
        releasePrev(time);
        releasePrev = null;
    }
    if (indexInChord === 0 || indexInChord === STEPS_PER_CHORD - 2) {
        let scale = buildScale(fromChord.key, fromChord.mode);
        let root = new Tone.Frequency(
            scale[fromChord.chordDegree] % 12 + 12,
            'midi'
        ).toNote();
        playBass(root, time, indexInChord === 0);
    }
}

function playNote(note: any, time: any) {
    playInternal(note, time);
}

function playBass(note: any, time: any, upBeat: any) {
    playInternalBass(note, time, upBeat);
}

function playInternal(note: any, time: any) {
    let freq = Tone.Frequency(note, 'midi');
    let echoed = Math.random() < 0.05;
    let smplr = echoed ? echoedSampler : sampler;
    smplr.triggerAttack(freq, time);
    if (echoed) {
        for (let i = 0; i < 10; i++) {
            let t: any = time + Tone.Time('16n').toSeconds() * i;
            let amt = 1 / (i + 1);
        }
    }
    return (t: any) => smplr.triggerRelease(freq, t);
}

function playInternalBass(note: any, time: any, upBeat: any) {
    if (upBeat) {
        bassSampler.triggerAttack(note, time);
    } else {
        bassLowSampler.triggerAttack(note, time);
    }
}

let keyNote = Tone.Frequency(key, 'midi').toNote();
let modeIndex = 0;

let bufferLoadPromise = new Promise(res => Tone.Buffer.on('load', res));

export const AudioStart = () => {
    Promise.all([rnn.initialize(), bufferLoadPromise]).then(() => {
        generateNext(Tone.now());
        Tone.Transport.scheduleRepeat(playNext, '16n', '8n');
        Tone.Transport.start();
    });
    StartAudioContext(Tone.context, '#ui');
}

export const SoundChange = (): Promise<void> => {
    return new Promise((resolve) => {
        pendingActions.push({
            mode: MODES[_.random(0, MODES.length - 1)],
            key: Tone.Frequency(KEYS[_.random(0, KEYS.length - 1)]).toMidi(),
            onDone: () => {
                console.info('mode changed')
                resolve();
            }
        });
    })
}
