import * as _ from 'lodash';
import React, { useRef } from 'react';
import { MyPoem } from "./poems";
import { Text } from '@react-three/drei';
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { color, getGrid, fontProps } from "./constants";


interface WProps {
    coef: number;
    word: string;
    x: number;
    y: number;
    r: number;
}


const Word = ({word, x, y, r, coef}: WProps) => {
    const ref = useRef();
    useFrame(() => {
        if (ref) {
            (ref.current as any).material.side = THREE.DoubleSide;
            switch (r) {
                case 1:
                    (ref.current as any).rotation.z += THREE.MathUtils.randFloat(-0.01, 0.01) * coef;
                    break;
                case 2:
                    (ref.current as any).rotation.y += 0.02 * coef;
                    break;
                case 3:
                    (ref.current as any).rotation.z += THREE.MathUtils.randFloat(0.01, 0.05) * coef;
                    break;
            }
        }
    });
    return (<Text
        ref={ref}
        {...fontProps}
        fontSize={THREE.MathUtils.randFloat(0.5, 2)}
        anchorX="center"
        children={word}
        position={[x, -1 * y, THREE.MathUtils.randFloat(-5, 5)]}
        color={color}
    />)
}

interface gProps {
    coef: number;
    words: string[];
    letters: { x: number, y: number }[];
}

const Grp = ({words, letters, coef}: gProps) => {
    const ref = useRef();

    return (
        <mesh
            ref={ref}
        >
            {letters.map((item, i) => {
                const word = _.sample(words);
                return (
                    <Word coef={coef} word={word as string} key={i} x={item.x} y={item.y}
                          r={THREE.MathUtils.randInt(0, 10)}/>
                )
            })}
        </mesh>)
}

export class Glyph extends React.Component<{ poem: MyPoem }, { current: number; }> {
    constructor(props: any) {
        super(props);
        this.state = {
            current: 0,
        }
    }

    componentDidMount() {
        setTimeout(() => this.inc(), THREE.MathUtils.randInt(200, 1500));
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<{ poem: MyPoem }>, nextContext: any) {
        this.setState({
            current: 0
        })
    }

    render() {
        const {current} = this.state;
        const {poem} = this.props;
        const letters = getGrid(poem.title[current]);
        const words = poem.poem.split("\n").map(item => item.trim()).join(" ").split(" ");

        return (
            <Grp words={words} letters={letters} coef={poem.coef}/>
        );
    }

    private inc() {
        const {current} = this.state;
        const {poem} = this.props;
        this.setState({
            current: current < poem.title.length - 1 ? current + 1 : 0
        })
        setTimeout(() => this.inc(), THREE.MathUtils.randInt(200, 1500));
    }
}
