import React, { useRef } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { MyPoem } from "./poems";
import { color, fontProps } from "./constants";

const radius = 10;

interface Props {
    poem: MyPoem
}

export const GlyphD: any = ({poem}: Props) => {
    const ref = useRef();
    const words = poem.poem.split("\n").map(item => item.trim()).join(" ").split(" ");
    const angle = Math.PI * 2 / words.length;
    useFrame(() => {
        if (ref) {
            (ref.current as any).rotation.z -= 0.0025 * poem.coef;
            (ref.current as any).rotation.y -= 0.0025 * poem.coef;
            (ref.current as any).rotation.x -= 0.0025 * poem.coef;
        }
    });
    return (
        <group
            ref={ref}
        >
            {
                words.map((word, i) => {
                    return (<group
                        key={i}
                        position={[0, 0, 0]}
                        rotation={[0, 0, i * angle * (-1)]}
                    >
                        <Text
                            {...fontProps}
                            anchorX="left"
                            children={word}
                            position={[THREE.MathUtils.randInt(radius - 2, radius + 2), 0, 0]}
                            color={color}
                        />
                    </group>);
                })}
        </group>
    );
}
