import * as THREE from "three";
import React, { useRef, useState } from 'react';
import { Text, CubicBezierLine } from '@react-three/drei';
import { angle, links, shuffled } from "./poems";
import { useFrame } from "@react-three/fiber";
import { color, fontProps, lineColor } from "./constants";

const radius = 10;

interface Props {
    onItemSelect(item: number): void;
}

export const Menu: any = (props: Props) => {
    const ref = useRef();
    const [hovered, setHover] = useState(false);
    useFrame(() => {
        if (!hovered && ref) {
            (ref.current as any).rotation.z += 0.00025;
        }
    });
    return (
        <group
            ref={ref}
        >
            {
                links.map((link, i) => {
                    const d1 = link[0] * angle;
                    const d2 = link[1] * angle;
                    return (
                        <CubicBezierLine
                            key={i}
                            start={[0.9 * radius * Math.cos(d1), 0.9 * radius * Math.sin(d1), 0]}
                            end={[0.9 * radius * Math.cos(d2), 0.9 * radius * Math.sin(d2), 0]}
                            midA={[0.5 * radius * Math.cos(d1), 0.5 * radius * Math.sin(d1), 0]}
                            midB={[0.5 * radius * Math.cos(d2), 0.5 * radius * Math.sin(d2), 0]}
                            color={lineColor}
                            lineWidth={0.5}
                        />
                    );
                })}
            {
                shuffled.map((poem, i) => {
                    const poemNo = `0${poem.idx + 1}`.slice(-2);
                    return (<group
                        key={i}
                        position={[0, 0, 0]}
                        rotation={[0, 0, i * angle]}
                    >
                        <Text
                            {...fontProps}
                            anchorX="left"
                            children={`${poemNo} - ${poem.title}`}
                            position={[radius, 0, 0]}
                            color={color}
                            onClick={() => {
                                props.onItemSelect(poem.idx)
                            }}
                            onPointerOver={() => setHover(true)}
                            onPointerOut={() => setHover(false)}
                        />
                    </group>);
                })}
        </group>
    );
}
