import * as React from 'react';

import logo from "../img/FPU_logo2_cierne.svg";

export const About: React.FC<{ lang: 'sk' | 'en' }> = ({lang}) => {
    console.info(lang);
    if (lang === 'en') {
        return (
            <>
                <h1>About the project Liza Gennart</h1>
                <p>
                    "Liza Gennart" is the name of a neural network (a fine-tuned GPT-2 model),
                    trained to write contemporary Slovak poetry. Promotive selections of the published
                    books in digital format were into our database (containing 2108 works) provided
                    by Slovak publishing houses Drewo a srd, ASPEKT, FACE, Skalná ruža, Dive
                    Buki, Európsky dom poézie and Ars Poetica, the database contains also all the
                    journal issues of VLNA, Glosolália, and Vertigo and to fill it in, we used also
                    some relevant poetic works from the digitized collection of copyright free works
                    called <a href="https://zlatyfond.sme.sk/" target="_blank">Zlatý fond denníka SME</a>. The collection of poems <a href="https://www.martinus.sk/?uItem=762293" target="_blank">Výsledky vzniku
                    (Outcomes of Origin)</a> was published by a Slovak publishing house <a href="http://www.drewoasrd.sk/" target="_blank">Drewo a srd</a> in
                    June 2020.
                </p>
                <p>
                    The intermediate project "Liza Gennart" is based on the idea and practical
                    conceptualisation of neural network generativity. This intermedial and
                    multiplatform work seeks to bring innovative, technologically engaging and
                    visually appealing poetry in a media environment to a wider range of the
                    public.
                </p>
                <p>
                    The aim is to conceptually embed poetry written by a neural network into
                    several media, to create several modes of perception and to present this
                    project through media-attractive design and accompanying activities. We
                    want to induce a multisensory perception, which would stimulate interest in
                    connecting the latest knowledge and realizations in the technological world
                    with the artistic context in Slovakia.
                </p>
                <p>Supported using public funding by Slovak Arts Council</p>
                <img src={logo} className="fpu-logo"/>
            </>)
    }
    return (
        <>
            <h1>O projekte Liza Gennart</h1>
            <p>
                "Liza Gennart" je meno neurónovej siete (vyladený model GPT-2),
                natrénovanej, aby písala súčasnú slovenskú poéziu. Promotívne výbery z
                knižných publikácií v digitálnej podobe do našej databázy (obsahujúcej 2108
                diel) poskytli vydavateľstvá Drewo a srd, ASPEKT, FACE, Skalná ruža,
                Dive Buki, Európsky dom poézie a Ars Poetica, databáza obsahuje aj
                všetky doterajšie čísla časopisov VLNA, Glosolália a Vertigo a na jej
                doplnenie sme využili aj voľne prístupné relevantné diela z digitalizovanej
                zbierky <a href="https://zlatyfond.sme.sk/" target="_blank">Zlatý fond denníka SME</a>. Poetická zbierka
                Lizy Gennart
                nazvaná <a href="https://www.martinus.sk/?uItem=762293" target="_blank">"Výsledky vzniku"</a> vyšla v
                júni 2020 vo vydavateľstve <a href="http://www.drewoasrd.sk/" target="_blank">Drewo a srd</a>.
            </p>
            <p>
                Intermediálny projekt "Liza Gennart" je založený na ideovom aj praktickom
                koncepte generatívnosti neurónovej siete. Toto intermediálne a
                multiplatformové dielo sa snaži priblížiť inovatívnu, technologicky pútavú a
                vizuálne lákavú poéziu v mediálnom prostredí širšiemu spektru verejnosti.
            </p>
            <p>
                Cieľom je konceptuálne zasadiť poéziu napísanú neurónovou sieťou do
                viacerých médií, vytvoriť viaceré módy percepcie a tento projekt predstaviť
                prostredníctvom mediálne atraktívneho dizajnu a sprievodných aktivít. Ide
                tu o navodenie multisenzorického vnímania, ktoré by podnietilo záujem o
                prepojenia najnovších poznatkov a realizácií v technologickom svete s
                umeleckým kontextom na Slovensku.
            </p>
            <p>
                Vznik diela intermediálneho diela "Liza Gennart" z verejných zdrojov
                podporil Fond na podporu umenia / Supported using public funding by
                Slovak Arts Council
            </p>
            <img src={logo} className="fpu-logo"/>
        </>
    );
}
