import * as Tone from 'tone';
import * as THREE from "three";
import React from 'react';
import { Canvas } from "@react-three/fiber";
import { EffectComposer, Bloom, Glitch, ChromaticAberration } from '@react-three/postprocessing'
import { Menu } from "./components/Menu";
import { poems } from "./components/poems";
import { SoundChange } from "./sound";
import { Glyph } from "./components/Confetti";
import { GlyphA } from "./components/ConfettiA";
import { GlyphB } from "./components/ConfettiB";
import { GlyphC } from "./components/ConfettiC";
import { GlyphD } from "./components/ConfettiD";
import { GlyphE } from "./components/ConfettiE";
import Sparks from "./components/Sparks";
import { Modal } from "./components/Modal";
import { About } from "./components/About";
import { Live } from "./components/Live";
import { Chat } from "./components/Chat";

import logo from "./img/FPU_logo2_cierne.svg";

const FX1: any = () => {
    return (
        <EffectComposer>
            <Bloom
                luminanceThreshold={0.0}
                luminanceSmoothing={0.025}
                height={300}
                opacity={0.7}
            />
            <Glitch
                delay={new THREE.Vector2(3, 10)}
                duration={new THREE.Vector2(0.025, 0.25)}
                strength={new THREE.Vector2(0.3, 1.0)}
                ratio={0.95}
            />
        </EffectComposer>
    )
}

const FX2: any = () => {
    return (
        <EffectComposer>
            <Bloom
                luminanceThreshold={0.0}
                luminanceSmoothing={0.025}
                height={300}
                opacity={0.2}
            />
            <ChromaticAberration
                offset={new THREE.Vector2(THREE.MathUtils.randFloat(0.001, 0.01), THREE.MathUtils.randFloat(0.001, 0.01))}
            />
        </EffectComposer>
    )
}

const FX3: any = () => {
    return (
        <EffectComposer>
            <Bloom
                luminanceThreshold={0.0}
                luminanceSmoothing={0.025}
                height={300}
                opacity={0.2}
            />
            <Glitch
                delay={new THREE.Vector2(5, 8)}
                duration={new THREE.Vector2(0.5, 1.5)}
                strength={new THREE.Vector2(0.8, 1.0)}
                ratio={0.5}
            />
        </EffectComposer>
    )
}

const FX4: any = () => {
    return (
        <EffectComposer>
            <Bloom
                luminanceThreshold={0.0}
                luminanceSmoothing={0.025}
                height={300}
                opacity={0.2}
            />
            <ChromaticAberration
                offset={new THREE.Vector2(THREE.MathUtils.randFloat(0.001, 0.01), THREE.MathUtils.randFloat(0.001, 0.01))}
            />
        </EffectComposer>
    )
}

const FX5: any = () => {
    return (
        <EffectComposer>
            <Bloom
                luminanceThreshold={0.0}
                luminanceSmoothing={0.025}
                height={300}
                opacity={0.2}
            />
            <Glitch
                delay={new THREE.Vector2(5, 8)}
                duration={new THREE.Vector2(0.001, 0.005)}
                strength={new THREE.Vector2(0.1, 0.2)}
                ratio={1}
            />
        </EffectComposer>
    )
}

interface Props {
}

interface Msg {
    toMaster: boolean;
    idx: number;
}

enum SELECTED_PAGE {
    ABOUT,
    LIVE,
    CHAT
}

interface State {
    poemId: number | null;
    player: any;
    timer: any;
    page: SELECTED_PAGE | null;
    lang: 'sk' | 'en';
}

export class ZApp extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            poemId: null,
            player: null,
            timer: null,
            page: null,
            lang: 'sk'
        }
    }

    render() {
        const {poemId, page} = this.state;
        return (
            <>
                <div className="three">
                    <nav>
                        <ul>
                            <li><a onClick={(e) => {
                                e.preventDefault();
                                this.openPage(SELECTED_PAGE.ABOUT, 'sk');
                            }}>O projekte</a><a onClick={(e) => {
                                e.preventDefault();
                                this.openPage(SELECTED_PAGE.ABOUT, 'en');
                            }}>About</a></li>
                            <li><a onClick={(e) => {
                                e.preventDefault();
                                this.openPage(SELECTED_PAGE.CHAT);
                            }}>Talk to Liza</a></li>
                            <li><a onClick={(e) => {
                                e.preventDefault();
                                this.openPage(SELECTED_PAGE.LIVE);
                            }}>Liza Live</a></li>
                            <li><a href={`${process.env.PUBLIC_URL}/pdf/Liza_Gennart_Vysledky_vzniku.pdf`}
                                   download="Liza_Gennart_Vysledky_vzniku.pdf">PDF download</a></li>
                        </ul>
                    </nav>
                    <Canvas dpr={[1, 2]} camera={{position: [0, 0, 35], fov: 90}}>
                        <>
                            <ambientLight/>
                            <Sparks count={20} colors={['#fff', '#FFEEB5', '#e0feff']} radius={10}/>
                        </>
                        {poemId !== null ?
                            (<>
                                {this.renderPoem(poemId)}
                            </>)
                            : (
                                <>
                                    <Menu
                                        onItemSelect={(poemId: number) => this.selectPoem(poemId)}
                                    />
                                </>
                            )}
                    </Canvas>
                    {page !== null ? (this.renderPage()) : null}
                </div>
                <footer>
                    <strong>Vznik diela z verejných zdrojov podporil Fond na podporu umenia / Supported using public
                        funding by Slovak Arts Council</strong>
                    <img src={logo} className="fpu-logo"/>
                </footer>
            </>
        )
    }

    private renderPage() {
        const {page, lang} = this.state;
        switch (page) {
            case SELECTED_PAGE.ABOUT:
                return (
                    <Modal onModalClose={() => this.onModalClose()}>
                        <About lang={lang}/>
                    </Modal>
                );
            case SELECTED_PAGE.LIVE:
                return (
                    <Modal onModalClose={() => this.onModalClose()}>
                        <Live/>
                    </Modal>
                );
            case SELECTED_PAGE.CHAT:
                return (
                    <Modal onModalClose={() => this.onModalClose()}>
                        <Chat/>
                    </Modal>
                );
            default:
                return null;
        }
    }

    private renderPoem(poemId: number) {
        const poem = poems[poemId];
        switch (poem.type) {
            case 0:
                return (
                    <>
                        <Glyph poem={poem}/>
                        <FX2/>
                    </>
                )
                break;
            case 1:
                return (<GlyphA poem={poem}/>)
                break;
            case 2:
                return (
                    <>
                        <GlyphB poem={poem}/>
                        <FX3/>
                    </>)
                break;
            case 3:
                return (<GlyphC poem={poem}/>)
                break;
            case 4:
                return (
                    <>
                        <GlyphD poem={poem}/>
                        <FX5/>
                    </>
                )
                break;
            case 5:
                return (
                    <>
                        <GlyphE poem={poem}/>
                        <FX4/>
                    </>)
                break;
            default:
                return (<GlyphE poem={poem}/>)
                break;
        }
    }

    private async selectPoem(pIdx: number) {
        const state = this.state;
        SoundChange();
        const {player, timer} = this.state;
        if (player) {
            player.stop();
        }
        if (timer) {
            clearTimeout(timer);
        }

        const file = `${process.env.PUBLIC_URL}/sound/text/${pIdx}.mp3`;
        const sound = new Tone.Player(file, () => {
            const newTimer = setTimeout(() => {
                this.setState({
                    ...state,
                    poemId: null,
                    player: null,
                    timer: null,
                })
            }, sound.buffer.duration * 1000 + 15000);
            this.setState({
                ...state,
                poemId: pIdx,
                player: sound,
                timer: newTimer,
            });
            sound.start();

        }).connect(new Tone.Gain(1).toMaster());
        // const sound = new Tone.Player(file, () => {
        //     const newTimer = setTimeout(() => {
        //             this.setState({
        //                 poemId: null,
        //                 player: null,
        //                 timer: null,
        //             });
        //         }, sound.buffer.duration * 1000 + 1500)};
        //             sound.start();
        //     }).connect(new Tone.Gain(1).toMaster());
    }

    private openPage = (page: SELECTED_PAGE, lang: 'sk' | 'en' = 'sk') => {
        const state = this.state;
        this.setState({
            ...state,
            page,
            lang
        });
    }

    private onModalClose = () => {
        const state = this.state;
        this.setState({
            ...state,
            page: null
        });
    }
}
