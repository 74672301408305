import * as THREE from 'three'
import React, { useRef, useMemo } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Line } from '@react-three/drei'

const r = () => Math.max(0.2, Math.random())

interface FatLineProps {
    curve: any;
    width: number;
    color: any;
    speed: any;
}

function Fatline({curve, width, color, speed}: FatLineProps) {
    const ref = useRef();
    useFrame(() => {
        if (ref.current && (ref.current as any).children) {
            ((ref.current as any).children as any[])[0].material.uniforms.dashOffset.value -= (speed*2);
        }
    });
    return (
        <mesh
            ref={ref}
        >
            <Line
                points={curve}
                color={color}
                lineWidth={width}
                dashed={true}
                dashSize={0.05}
                dashScale={0.2}
                dashOffset={0}
            />
        </mesh>
    )
}

export default function Sparks({
                                   count,
                                   colors,
                                   radius = 10
                               }: { count: number, colors: string[], radius: number }) {
    const lines = useMemo(
        () =>
            new Array(count).fill(count).map((_, index) => {
                const pos = new THREE.Vector3(Math.sin(0) * radius * r(), Math.cos(0) * radius * r(), 0)
                const points = new Array(30).fill(30).map((_, index) => {
                    const angle = (index / 20) * Math.PI * 2
                    return pos.add(new THREE.Vector3(Math.sin(angle) * radius * r(), Math.cos(angle) * radius * r(), 0)).clone()
                })
                const curve = new THREE.CatmullRomCurve3(points).getPoints(1000)
                return {
                    color: colors[Math.floor(colors.length * Math.random())],
                    width: Math.max(0.1, (0.2 * index) / 10),
                    speed: Math.max(0.001, 0.004 * Math.random()),
                    curve
                }
            }),
        [count]
    )

    const ref = useRef()
    const {size, viewport} = useThree()
    const aspect = size.width / viewport.width
    useFrame(() => {
        if (ref.current) {
            const rf = ref.current as any;
            rf.rotation.x = THREE.MathUtils.lerp(rf.rotation.x, 0 + aspect / 200, 0.1)
            rf.rotation.y = THREE.MathUtils.lerp(rf.rotation.y, 0 + aspect / 400, 0.1)
        }
    })

    return (
        <group ref={ref}>
            <group position={[-radius * 2, -radius, -10]} scale={[1, 1.3, 1]}>
                {lines.map((props, index) => (
                    <Fatline key={index} {...props} />
                ))}
            </group>
        </group>
    )
}
