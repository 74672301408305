import * as THREE from "three";

export const fontProps = {
    font: '/fonts/JetBrainsMono-Bold.woff',
    fontSize: 1.2,
    letterSpacing: 0,
    lineHeight: 1,
    'material-toneMapped': false,
    outlineColor: new THREE.Color().set('#151525'),
    outlineWidth: 0.1,
    outlineBlur: 0.2,
    'side': THREE.DoubleSide,
}

export const bgCcolor = new THREE.Color().set('#050505');

export const lineColor = new THREE.Color().set('#353535');

export const color = new THREE.Color().set('#f5f5f5');

export const getGrid = (text: string): { x: number, y: number }[] => {
    const typeCanvas = document.createElement('canvas');
    const typeContext = typeCanvas.getContext('2d');
    const cell = 30;
    const cols = Math.floor((1600 * text.length) / cell);
    const rows = Math.floor(1600 / cell);
    const numCells = cols * rows;
    const fontFamily = 'serif';
    const fontSize = rows * 1.2;
    typeCanvas.width = cols;
    typeCanvas.height = rows;


    if (typeContext) {
        typeContext.fillStyle = 'black';
        typeContext.fillRect(0, 0, cols, rows);
        typeContext.fillStyle = 'white';
        typeContext.font = `${fontSize}px ${fontFamily}`;
        typeContext.textBaseline = 'top';

        const metrics = typeContext.measureText(text);
        const mx = metrics.actualBoundingBoxLeft * -1;
        const my = metrics.actualBoundingBoxAscent * -1;
        const mw = metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight;
        const mh = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

        const tx = (cols - mw) * 0.5 - mx;
        const ty = (rows - mh) * 0.5 - my;

        typeContext.save();
        typeContext.translate(tx, ty);

        typeContext.beginPath();
        typeContext.rect(mx, my, mw, mh);
        typeContext.stroke();

        typeContext.fillText(text, 0, 0);
        typeContext.restore();

        const typeData = typeContext.getImageData(0, 0, cols, rows).data;
        const letters = [];
        for (let i = 0; i < numCells; i++) {
            const col = (i % cols) - (cols / 2);
            const row = Math.floor(i / cols) - (rows / 2);
            const r = typeData[i * 4 + 0];
            if (r === 255) {
                letters.push({x: col, y: row})
            }
        }
        return letters;
    }
    return [];
}
